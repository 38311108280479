import _ from 'lodash'
import { FormPlugin, FormPreset, FormsFieldPreset } from '@wix/forms-common'
import successMessageStructure from '../../../assets/presets/hidden-message.json'
import translations from '../../../utils/translations'
import {
  handleAfterSubmitBehavior,
  hideFormInMobile,
  ContactFormEnhancer,
  createBaseStructure,
  emailEnhancer,
  behaviorsEnhancer,
  createSubmitButtonComponent,
  updateConfig,
  updateRequired,
  updateTextAlignment,
  ContactFormFieldEnhancer,
  updateCrmLabel,
  fixDuplicatedCrmLabels,
  enhanceWithStyle,
} from './contact-form-service'
import { ROLE_TITLE } from '../../../constants/roles'
import { fieldsStore } from '../preset/fields/fields-store'
import { FontOption } from '@wix/platform-editor-sdk'
import subscribeFormEnvelop from '../../../assets/presets/subscribe-form-envelop.json'

const skinExtraComponents = {
  'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutTransparentWithIcon': [
    subscribeFormEnvelop,
  ],
}

const createSuccessMessageComponent = (contactForm: ContactForm): RawComponentStructure =>
  _.merge({}, successMessageStructure, {
    layout: {
      width: contactForm.layout.width - 60,
      height: 21,
      x: 60,
      y: 102,
    },
    data: {
      text: `<p class="font_8"><span style="color:#8FCA8F;">${
        contactForm.data.successMessage ||
        translations.t('subscribeContactForm.defaultSuccessMessage')
      }</span></p>`,
    },
  })

export const convertSubscribeContactFormToWixForms = (
  subscribeContactForm: SubscribeContactForm,
  fontMap,
  fontOptions: FontOption[],
): RawComponentStructure => {
  const titleComponent: RawComponentStructure = _.merge({}, successMessageStructure, {
    layout: {
      width: subscribeContactForm.layout.width - 60,
      height: 21,
      x: 60,
      y: 0,
    },
    data: {
      text: `<h1 class="font_5"><span style="color:#000000;">${subscribeContactForm.data.subscribeFormTitle}</span></h1>`,
    },
    role: ROLE_TITLE,
  })
  const fields = fetchFields(subscribeContactForm)
  const components: RawComponentStructure[] = [
    titleComponent,
    ...(skinExtraComponents[subscribeContactForm.skin] || []),
    ...fields,
    createSubmitButtonComponent(subscribeContactForm),
    createSuccessMessageComponent(subscribeContactForm),
  ]
  const baseStructure = _.merge({}, createBaseStructure(subscribeContactForm), {
    components,
    config: {
      preset: FormPreset.GET_SUBSCRIBERS,
      formName: translations.t('subscribeContactForm.defaultFormName'),
      plugins: [
        {
          id: FormPlugin.GET_SUBSCRIBERS,
        },
      ],
    },
  })
  const enhancers: ContactFormEnhancer[] = [
    handleAfterSubmitBehavior,
    hideFormInMobile,
    emailEnhancer,
    behaviorsEnhancer,
    fixDuplicatedCrmLabels,
    enhanceWithStyle,
  ]
  const convertedStructure = enhancers.reduce<RawComponentStructure>(
    (previousStructure, enhancer) =>
      enhancer(subscribeContactForm, previousStructure, fontMap, fontOptions),
    baseStructure,
  )
  return convertedStructure
}

const updatePlaceholder: ContactFormFieldEnhancer = (
  _contactForm: ContactForm,
  dynamicField: ContactFormField,
  convertedField: RawComponentStructure,
): RawComponentStructure => {
  convertedField.data.label = ''
  convertedField.data.placeholder = dynamicField.displayLabel
  convertedField.props.placeholder = dynamicField.displayLabel
  return convertedField
}

const paddingEnhancer: ContactFormFieldEnhancer = (contactForm, _field, convertedField) => {
  if (
    contactForm.skin ===
    'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormPlaceholderSkin'
  ) {
    return _.merge({}, convertedField, { props: { textPadding: 5 } })
  }
  return _.merge({}, convertedField, { props: { textPadding: 7 } })
}

const fieldEnhancers: ContactFormFieldEnhancer[] = [
  updateConfig,
  updateCrmLabel,
  updatePlaceholder,
  updateRequired,
  updateTextAlignment,
  paddingEnhancer,
]

const createBaseField = (subscribeContactFormField: ContactFormField) => {
  switch (subscribeContactFormField.name) {
    case 'FirstNameField':
      return fieldsStore.allFieldsData[FormsFieldPreset.FIRST_NAME]
    case 'LastNameField':
      return fieldsStore.allFieldsData[FormsFieldPreset.LAST_NAME]
    case 'PhoneField':
      return fieldsStore.allFieldsData[FormsFieldPreset.PHONE]
    case 'EmailField':
      return fieldsStore.allFieldsData[FormsFieldPreset.MAIN_EMAIL]
    default:
      return null
  }
}

const fetchFields = (contactForm: SubscribeContactForm): RawComponentStructure[] =>
  ['hiddenFirstNameField', 'hiddenLastNameField', 'hiddenEmailField', 'hiddenPhoneField'].reduce(
    (acc, fieldKey) => {
      if (contactForm.props[fieldKey]) {
        const fieldName = _.replace(fieldKey, 'hidden', '')
        const field: ContactFormField = {
          name: fieldName,
          required: contactForm.props[`required${fieldName}`],
          displayLabel: contactForm.data[`${_.lowerFirst(fieldName)}Label`],
        }
        const baseField = createBaseField(field).fieldStructure() as RawComponentStructure
        const convertedFieldStructure = fieldEnhancers.reduce<RawComponentStructure>(
          (previousFieldStructure, enhancer) =>
            enhancer(contactForm, field, previousFieldStructure),
          baseField,
        )
        return [...acc, convertedFieldStructure]
      }
      return acc
    },
    [],
  )
